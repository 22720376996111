export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'ABOUT US',
    headline1: 'Our mission is to  ',
    headline2:'empower developers ',
    headline3:'with the resources they need to become leaders in tech.',
    description: "Frontera Devs is an award-winning organization dedicated to making computer science education equitable across the Rio Grande Vallev. We're a developer-first organization that organizes hands-on workshops to teach industry resources and helps guide early career devs through the process of defining their career since 2019.",
    buttonLabel: 'Become a Member',
    imgStart: false,
    img: require('../../images/svg-1.svg').default,
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false
}

export const homeObjTwo = {
    id: 'discover',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Premium Bank',
    headline: 'Unlimited Transactions with zero fees',
    description: 'Get access to our exclusive app that allows you to send unlimited transactions without getting chaged any fees.',
    buttonLabel: 'Learn More',
    imgStart: true,
    img: require('../../images/svg-2.svg').default,
    alt: 'Piggybank',
    dark: true,
    primary: true,
    darkText: false
}

export const homeObjThree = {
    id: 'signup',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Placeholder',
    headline: 'Frequently Asked Questions',
    description: 'Placeholder',
    buttonLabel: 'Get started',
    imgStart: false,
    img: require('../../images/svg-3.svg').default,
    alt: 'Paper',
    dark: true,
    primary: true,
    darkText: false
}

export const homeObjFour = {
    id: 'four',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Placeholder',
    headline: '',
    description: 'Made with love by FD team',
    buttonLabel: 'Get started',
    imgStart: false,
    img: require('../../images/svg-3.svg').default,
    alt: 'Paper',
    dark: true,
    primary: true,
    darkText: false
}